<template>
  <div class="visit">
    <!-- <swipe-content /> -->
    <div class="visit_title_bg"></div>
    <div class="visit_title_text">体验预约</div>
    <van-form @submit="onSubmit" class="visitForm">
      <van-field
        v-model="state.username"
        name="username"
        label="姓名："
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="state.phone"
        name="phone"
        label="联系方式："
        type="tel"
        placeholder="联系方式"
        :rules="[{ validator, message: '请填写联系方式' }]"
      />
      <van-field
        v-model="state.time"
        readonly
        clickable 
        name="time"
        label="预约时间："
        placeholder="点击选择日期"
        @click="state.showCalendar = true"
        :rules="[{ required: true, message: '请选择预约时间' }]"
      />
      <van-calendar
        color="rgba(99, 49, 240, 1)"
        type="single"
        v-model:show="state.showCalendar"
        @confirm="onConfirmTime"
        :show-confirm="false"
      />
      <van-field
        v-model="state.store"
        readonly
        clickable
        name="store"
        label="预约门店："
        placeholder="点击选择参观门店"
        @click="state.showPicker = true"
        :rules="[{ required: true, message: '请选择预约门店' }]"
      />
      <van-popup v-model:show="state.showPicker" position="bottom">
        <van-picker
          :columns="columns"
          @confirm="onConfirmShop"
          @cancel="state.showPicker = false"
        />
      </van-popup>
      <div class="visitForm_btn">
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          color="rgba(99, 49, 240, 1)"
          >提交</van-button>
      </div>
    </van-form>
    <contact-info />
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import contactInfo from "../components/ContactInfo.vue";
import { useRouter } from "vue-router";

export default {
  name: "Visit",
  components: {
    contactInfo,
  },
  setup() {
    const router = useRouter();
    onMounted(() => {
      init();
    });
    const init = async () => {};
    const state = reactive({
      username: "",
      phone: "",
      time: "",
      showCalendar: false,
      showPicker: false,
    });
    const onSubmit = (values) => {
      console.log("submit", values);
      sessionStorage.setItem('appointmentInfo',JSON.stringify(values))
      // 跳转预约成功页
      router.push({ path: "appointment" });
    };
    // 时间格式化
    const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    // 确认时间选择
    const onConfirmTime = (date) => {
      state.time = formatDate(date);
      state.showCalendar = false;
    };
    // 门店选择
    const columns = ["北京常营店"];
    const onConfirmShop = (value) => {
      state.store = value;
      state.showPicker = false;
    };

    // 校验函数返回 true 表示校验通过，false 表示不通过
    const validator = (val) => /^1[3456789]\d{9}$/.test(val);
    return {
      columns,
      state,
      onSubmit,
      onConfirmTime,
      onConfirmShop,
      validator,
    };
  },
};
</script>

<style lang="scss" scoped>
.visit {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  .visit_title_bg{
    filter: blur(2px);
    background: url('../assets/image/title.jpg') repeat;
    background-size: 100%;
    width: 100%;
    height: auto;
    overflow: hidden;
    height: 80px;
  }
  .visit_title_text{
    width: 100%;
    height: auto;
    overflow: hidden;
    height: 80px;
    line-height: 80px;
    color: #fff;
    letter-spacing: 5px;
    position: absolute;
    z-index: 333;
    top: 0;
    text-shadow: 1px 1px 0px #fff;
    font-size: 24px;
  }
  .visitForm {
    background: #fff;
    width: 100%;
    height: auto;
    padding-bottom: 16px;
    .visitForm_btn {
      margin: 16px;
    }
  }
}
</style>
